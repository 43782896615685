import * as React from "react";
import {Seo} from "../components/Seo";
import {LatestPosts} from "../components/pages/home/LatestPosts";
import {TopRatedAnime} from "../components/pages/home/TopRatedAnime";
import {MangaList} from "../components/MangaList";
import {Newsletter} from "../components/Newsletter";
import {graphql, Link, useStaticQuery} from "gatsby";
import {Google} from "../components/Google";
import {Socials} from "../components/Socials";
import {routes} from "../util/routes";
import {GatsbyImage} from "gatsby-plugin-image";

const IndexPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      graphCmsAsset(
        handle: { eq: "oxKRhCGKSk2MFJZNIVTE" }
        stage: { eq: PUBLISHED }
      ) {
        id
        gatsbyImageData(quality: 70, placeholder: BLURRED, width: 208)
        handle
      }
    }
  `);

  return (
    <>
      <Seo />
      <section className="mt-5 my-10 mb-3 md:pt-10 relative md:py-20 px-5 max-w-6xl mx-auto flex-row flex flex-wrap">
        <div>
          <h1 className="text-3xl md:text-5xl dark:text-gray-300 mb-4 font-medium">
            Hi, I'm <span className="text-blue-500">Gregory Gaines</span>
          </h1>
          <div className="col-span-3 max-w-2xl">
            <p className="px-0 dark:text-gray-300 text-lg text-left md:leading-8 text-gray-600 mb-4">
              A software engineer that specializes in serverless microservices
              working for <Google />, making the internet more accessible for
              the world. I love creating helpful content and writing about
              programming, reverse engineering, and computer science.
            </p>

            <Socials className="mb-4" />

            <div className="space-x-0 space-y-5 sm:space-y-0 sm:space-x-2">
              <Link
                className="focus:ring-4 dark:text-blue-400 dark:border-blue-400 dark:hover:bg-blue-400 dark:hover:text-white text-blue-600 border border-blue-500 hover:bg-blue-500 hover:text-white inline-flex items-center justify-center w-full sm:w-auto py-3 px-5 rounded text-white shadow-md font-bold"
                to={routes.aboutMe}
              >
                Learn about me
              </Link>
              <Link
                className="focus:ring-4 inline-flex items-center justify-center w-full sm:w-auto py-3 px-5 border border-cgreen rounded bg-cgreen text-white hover:bg-white hover:text-green-500 hover:border hover:border-green-500 shadow-md font-bold"
                to={routes.blog}
              >
                Check out my blog
              </Link>
            </div>
          </div>
        </div>

        {/*w-64 h-64*/}
        <div className=" mx-auto mb-10 md:mb-0 md:ml-auto mt-10 lg:mt-0">
          <GatsbyImage
            imgClassName="mx-auto"
            loading="lazy"
            className="h-72 w-72 rounded-md shadow-xl mx-auto"
            alt="Gregory Gaines"
            image={data.graphCmsAsset.gatsbyImageData}
          />
        </div>
      </section>
      <LatestPosts />
      <TopRatedAnime />
      <MangaList />
      <Newsletter location={location} />
    </>
  );
};

export default IndexPage;
